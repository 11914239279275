import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import ValidationAccountPanel from "../../Panels/Validations/ValidationAccount";
import ValidationOffersPanel from "../../Panels/Validations/ValidationOffers";
import ValidationAccountService from "../../../services/ValidationAccountService";
import AccessGroupService from "../../../services/AccessGroupService";
import RateService from "../../../services/RateService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { isUndefined, isNaN } from "lodash";
import clsx from "clsx";
import ValidationUsersPanel from "../../Panels/ValidationUsers";
import OnePassPanel from "../../Panels/Validations/OnePass/OnePassPanel";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const validationAccountService = new ValidationAccountService(apiClient);
const accessGroupService = new AccessGroupService(apiClient);
const rateService = new RateService(apiClient);

const ValidationAccountForm = ({
  contractHolderID,
  onChange,
  onDelete,
  facilityID,
}) => {
  const classes = useStyles();
  const [accountIsFetched, setAccountIsFetched] = useState(false);
  const [validationAccount, setValidationAccount] = useState({});
  const [groups, setGroups] = useState([]);
  const [rates, setRates] = useState([]);
  const [validationAccountOnePassDetails, setValidationAccountOnePassDetails] = useState({
    accessGroupId: "",
    overstayRateId: "",
    defaultExpirationTime: null,
    showRoomField: false,
    showNameField: false,
    active: false,
  });
  const enqueueSnackbar = useEnqueueSnackbar();

  const isOnePassFeatureEnabled = useFeatureFlag("ONE Pass");

  const handleValidationAccountChange = (validationAccount) => {
    setValidationAccount(validationAccount);
    onChange(validationAccount);
  };

  const handleValidationAccountDelete = () => {
    setValidationAccount({ contractHolderID: undefined });
    onDelete();
  };

  const fetchValidationAccount = async (facilityID, contractHolderID) => {
    let response;
    try {
      response = await validationAccountService.getValidationAccount(
        facilityID,
        contractHolderID
      );
    } catch {
      enqueueSnackbar(
        `Failed to retrieve Validation Account for ${facilityID}, ${contractHolderID}`,
        {
          variant: "error",
          tag: "FailedToRetrieveValidationAccount",
        }
      );
      return;
    }
    onChange(response.data);
    setValidationAccount({ ...validationAccount, ...response.data });
    setAccountIsFetched(true);
  };

  const fetchAccessGroups = useCallback(async (facilityID) => {
    let response;
    try {
      response = await accessGroupService.GetAccessGroups(facilityID, 100, 0, "");
    } catch {
      enqueueSnackbar("Failed to retrieve access groups", {
        variant: "error",
        tag: "fetchAccessGroupsError"
      });
      return;
    }

    let tmpGroups =
      response?.data?.collection?.filter(group => group?.type === "Normal")
      .map(group => ({ name: group.name, value: group.accessGroupID })) ?? [];
    setGroups(tmpGroups);
  }, [facilityID]);

  const fetchRates = useCallback(async (facilityID) => {
    let response;
    try {
      response = await rateService.getAvailableRates(facilityID)
    } catch {
      enqueueSnackbar("Failed to retrieve rates", {
        variant: "error",
        tag: "fetchRatesError"
      });
      return;
    }

    let tmpRates =
      response?.data.map(rate => ({ name: rate.name, rateID: rate.rateID, value: rate.rateBlobID }))
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    setRates(tmpRates);
  }, [facilityID]);

  const fetchValidationAccountOnePassDetails = useCallback(async (contractHolderID) => {
    let response;
    try {
      response = await validationAccountService.getValidationAccountOnePassDetails(contractHolderID, facilityID)
    } catch {
      enqueueSnackbar("Failed to retrieve validation account details", {
        variant: "error",
        tag: "fetchValidationAccountOnePassDetailsError"
      });
      return;
    }
    if(response?.data) {
      const { contractHolderId, entityId, ...onePassDetails } = response.data;
      setValidationAccountOnePassDetails(onePassDetails);
    }
  }, [contractHolderID, facilityID])

  useEffect(() => {
    if (
      (!isUndefined(validationAccount.contractHolderID) ||
        Object.keys(validationAccount).length === 0) &&
      !isUndefined(contractHolderID) &&
      !isNaN(contractHolderID)
    ) {
      fetchValidationAccount(facilityID, contractHolderID);
      if (isOnePassFeatureEnabled) {
        fetchValidationAccountOnePassDetails(contractHolderID);
      }
    } else {
      setAccountIsFetched(true);
    }
  }, [facilityID, validationAccount.contractID]);

  useEffect(() => {
    if (isOnePassFeatureEnabled) {
      fetchAccessGroups(facilityID)
      fetchRates(facilityID);
    }
  }, [facilityID, isOnePassFeatureEnabled, fetchAccessGroups, fetchRates]);

  const handleValidationOnePassDetailsChange = async (onePassDetails) => {
    let response;
    try {
      if(validationAccountOnePassDetails.accessGroupId) {
        response = await validationAccountService.updateValidationAccountOnePassDetails({ ...onePassDetails, contractHolderId: contractHolderID, entityId: facilityID });
      } else {
        response = await validationAccountService.createValidationAccountOnePassDetails({ ...onePassDetails, contractHolderId: contractHolderID, entityId: facilityID });
      }
    } catch {
      enqueueSnackbar("Failed to save validation onepass details", {
        variant: "error",
        tag: "saveValidationAccountOnePassDetailsError"
      });
      return;
    }
    const { contractHolderId, entityId, ...fetchDetails } = response.data;
    setValidationAccountOnePassDetails(fetchDetails);
  }

  return (
    <div className={classes.root}>
      {accountIsFetched && (
        <ValidationAccountPanel
          data-testid={"validation-account-panel"}
          className={clsx(["validation-account-panel", classes.flexChild])}
          validationAccountData={validationAccount}
          contractHolderID={contractHolderID}
          onChange={handleValidationAccountChange}
          onDelete={handleValidationAccountDelete}
        />
      )}
      {contractHolderID && accountIsFetched && isOnePassFeatureEnabled &&  (
        <OnePassPanel
          data-testid={"one-pass-panel"}
          className={clsx(["one-pass-panel", classes.flexChild])}
          accessGroups={groups}
          rates={rates}
          onePassDetails={validationAccountOnePassDetails}
          onSave={handleValidationOnePassDetailsChange}
        />
      )}
      {accountIsFetched && (
        <ValidationOffersPanel
          data-testid={"validation-offers-panel"}
          className={clsx(["validation-offers-panel", classes.flexChild])}
          entityID={facilityID}
          validationAccountData={validationAccount}
          contractHolderID={contractHolderID}
        />
      )}
      {accountIsFetched && (
        <ValidationUsersPanel
          className={clsx("validation-user-panel", classes.flexChild)}
          validationAccountID={contractHolderID}
        />
      )}
    </div>
  );
};

ValidationAccountForm.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
};

ValidationAccountForm.propTypes = {
  validationAccountName: PropTypes.string,
  contractHolderID: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ValidationAccountForm;
